
import { defineComponent, ref } from 'vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'

export default defineComponent({
  components: {
    TmFormLine,
  },
  setup() {
    const color = ref('#000000')
    const colorRgba = ref('rgba(0, 0, 0, 1)')

    return {
      color,
      colorRgba,
    }
  },
})
